import React, { useRef, useContext, useState } from 'react';
import ChapterVideo from './ChapterVideo';
import { UserContext } from "../App";
import GetAppIcon from '@mui/icons-material/GetApp';
import SchoolIcon from '@mui/icons-material/School';
import { Link } from "react-router-dom";

import {
  Accordion,
  Card,
  ListGroup,
  ListGroupItem
} from 'react-bootstrap';

import { Container } from 'reactstrap';

const Course = (props) => {

  const { myCourses, user } = useContext(UserContext);

  const { grade, semester } = props;

  const courseData = (myCourses.length > 0) ? myCourses.find(x => x.grade == grade && x.semester == semester) : null;

  // To Bug: 透過 API 取得課程資料
  // 傳入參數 grade, semester 以取得對應的課程各單元 (chapters) 完整資料

  const course = {
    "grade": 1,
    "semester": 1,
    "title": "★扶輪芯福線上EQ課(一上)",
    "chapters": [
      {
        "id": 1,
        "seq": 1,
        "title": "第1課-發現好心情",
        "videos": [
          {
            "id": "a983ecc8baa34b778d74867547baa21c", "title": "課程影片",
            "metadata": [
              {
                "t": "0:34",
                "label": "發現好心情",
                "action": "引導複誦"
              },
              {
                "t": "4:54",
                "label": "表情動作：開心",
                "action": "動作"
              },
              {
                "t": "5:25",
                "label": "表情動作：驚喜",
                "action": "動作"
              },
              {
                "t": "5:46",
                "label": "表情動作：得意",
                "action": "動作"
              },
              {
                "t": "6:07",
                "label": "表情動作：有趣",
                "action": "動作"
              },
              {
                "t": "11:28",
                "label": "回顧小飛翔快樂的一天 (老師帶領討論)",
                "action": "討論"
              },
              {
                "t": "16:08",
                "label": "好心情躲貓貓 活動說明",
                "action": "討論"
              },
              {
                "t": "17:41",
                "label": "(作答)",
                "action": "討論"
              },
              {
                "t": "17:54",
                "label": "開心小隊",
                "action": "討論"
              },
              {
                "t": "18:42",
                "label": "(作答)",
                "action": "討論"
              },
              {
                "t": "18:57",
                "label": "驚喜小隊",
                "action": "討論"
              },
              {
                "t": "19:43",
                "label": "(作答)",
                "action": "討論"
              },
              {
                "t": "20:00",
                "label": "得意小隊",
                "action": "討論"
              },
              {
                "t": "20:47",
                "label": "(作答)",
                "action": "討論"
              },
              {
                "t": "21:01",
                "label": "有趣小隊",
                "action": "討論"
              },
              {
                "t": "21:49",
                "label": "(作答)",
                "action": "討論"
              },
              {
                "t": "22:03",
                "label": "得意小隊",
                "action": "討論"
              },
              {
                "t": "22:47",
                "label": "(作答)",
                "action": "討論"
              },
              {
                "t": "23:02",
                "label": "驚喜小隊",
                "action": "討論"
              },
              {
                "t": "23:45",
                "label": "(作答)",
                "action": "討論"
              },
              {
                "t": "23:58",
                "label": "有趣小隊",
                "action": "討論"
              },
              {
                "t": "24:42",
                "label": "(作答)",
                "action": "討論"
              },
              {
                "t": "24:52",
                "label": "開心小隊",
                "action": "討論"
              },
              {
                "t": "25:31",
                "label": "討論",
                "note": "帶領討論：想一想、說說看",
                "action": "討論"
              },
              {
                "t": "26:14",
                "label": "(作答) 得意",
                "action": "討論"
              },
              {
                "t": "26:30",
                "label": "(作答) 驚喜",
                "action": "討論"
              },
              {
                "t": "26:47",
                "label": "(作答) 有趣",
                "action": "討論"
              },
              {
                "t": "27:07",
                "label": "(作答) 開心",
                "action": "討論"
              },
              {
                "t": "27:25",
                "label": "(作答) 好心情麥克風",
                "action": "討論"
              },
              {
                "t": "27:41",
                "label": "(作答) 用法: 發現好心情",
                "action": "討論"
              }
            ]
          }
        ]
      },
      {
        "id": 2,
        "seq": 2,
        "title": "第2課-分享好心情",
        "videos": [
          {
            "id": "4dfd70772fcd48668a417b78f64b86e2", "title": "影片2",
            "metadata": [
              {
                "t": "0:41",
                "label": "分享好心情",
                "action": "引導複誦"
              },
              {
                "t": "3:48",
                "label": "心情好朋友",
                "action": "舉牌"
              },
              {
                "t": "11:49",
                "label": "分組說明",
                "action": "說明"
              },
              {
                "t": "12:42",
                "label": "(舉牌)",
                "action": "動作"
              },
              {
                "t": "13:13",
                "label": "(複誦)",
                "action": "引導複誦"
              },
              {
                "t": "13:54",
                "label": "(舉牌)",
                "action": "動作"
              },
              {
                "t": "14:18",
                "label": "(複誦)",
                "action": "引導複誦"
              },
              {
                "t": "14:30",
                "label": "(舉牌)",
                "action": "動作"
              },
              {
                "t": "14:53",
                "label": "(複誦)",
                "action": "引導複誦"
              },
              {
                "t": "15:05",
                "label": "(舉牌)",
                "action": "動作"
              },
              {
                "t": "15:25",
                "label": "(複誦)",
                "action": "引導複誦"
              },
              {
                "t": "15:45",     // next set #1
                "label": "(舉牌)",
                "action": "動作"
              },
              {
                "t": "16:11",
                "label": "(複誦)",
                "action": "引導複誦"
              },
              {
                "t": "16:21",
                "label": "(舉牌)", // 驚喜
                "action": "動作"
              },
              {
                "t": "16:48",
                "label": "(複誦)",
                "action": "引導複誦"
              },
              {
                "t": "16:59",
                "label": "(舉牌)", // 得意
                "action": "動作"
              },
              {
                "t": "17:21",
                "label": "(複誦)",
                "action": "引導複誦"
              },
              {
                "t": "17:32",
                "label": "(舉牌)", // 有趣
                "action": "動作"
              },
              {
                "t": "17:57",
                "label": "(複誦)",
                "action": "引導複誦"
              },
              {
                "t": "18:23",
                "label": "(計時開始)",
                "action": "暫停說明"
              },
              {
                "t": "26:31",
                "label": "帶領討論：想一想、說說看",
                "action": "暫停"
              },
              {
                "t": "27:26",
                "label": "(第一題)",
                "action": "暫停"
              },
              {
                "t": "27:45",
                "label": "(第二題)",
                "action": "暫停"
              },
              {
                "t": "20:00",
                "label": "(第三題)",
                "action": "暫停"
              },
              {
                "t": "28:23",
                "label": "(第四題)",
                "action": "暫停"
              }
            ],
          }
        ]
      },
      {
        "id": 3,
        "seq": 3,
        "title": "第3課-發現壞心情(轉)",
        "videos": [
          {
            "id": "dee999c2d66d44bf8eb3f44b1ea9aec4", "title": "影片3",
            "metadata": [
              {
                "t": "0:52",
                "label": "發現壞心情",
                "action": "引導複誦"
              },
              {
                "t": "3:22",
                "label": "緊張的表情、動作",
                "action": "暫停"
              },
              {
                "t": "3:44",
                "label": "難過的表情、動作",
                "action": "暫停"
              },
              {
                "t": "4:08",
                "label": "無聊的表情、動作",
                "action": "暫停"
              },
              {
                "t": "4:39",
                "label": "害怕的表情、動作",
                "action": "暫停"
              },
              {
                "t": "16:40",
                "label": "練習小喇叭-叭",
                "action": "暫停"
              },
              {
                "t": "16:45",
                "label": "練習大喇叭-叭叭叭",
                "action": "暫停"
              },
              {
                "t": "17:12",
                "label": "忘記帶課本...",
                "action": "暫停"
              },
              {
                "t": "18:30",
                "label": "不能用最喜歡的筆畫畫...",
                "action": "暫停"
              },
              {
                "t": "19:15",
                "label": "沒有人可以聊天，很無聊...",
                "action": "暫停"
              },
              {
                "t": "20:00",
                "label": "一個人也沒有的地方，很害怕...",
                "action": "暫停"
              },
              {
                "t": "21:32",
                "label": "弄壞同學的玩具飛機，很害怕...",
                "action": "暫停"
              },
              {
                "t": "22:14",
                "label": "舉手沒被叫到，很難過...",
                "action": "暫停"
              },
              {
                "t": "22:55",
                "label": "寫習作碰到不會的問題，很緊張...",
                "action": "暫停"
              },
              {
                "t": "23:41",
                "label": "不想玩球又不知道做什麼好，很無聊...",
                "action": "暫停"
              },
              {
                "t": "24:39",
                "label": "帶領討論：想一想、說說看",
                "action": "暫停"
              },
              {
                "t": "25:24",
                "label": "第一題",
                "action": "暫停"
              },
              {
                "t": "25:43",
                "label": "第二題",
                "action": "暫停"
              },
              {
                "t": "26:00",
                "label": "第三題",
                "action": "暫停"
              },
              {
                "t": "26:17",
                "label": "第四題",
                "action": "暫停"
              },
              {
                "t": "26:34",
                "label": "第五題",
                "action": "暫停"
              }
            ]
          }
        ]
      },
      {
        "id": 4,
        "seq": 4,
        "title": "第4課-幫幫壞心情",
        "videos": [
          {
            "id": "edb99188fbcf41c48694ff8af599ad37", "title": "影片4",
            "metadata": [
              {
                "t": "0:49",
                "label": "幫幫壞心情",
                "action": "引導複誦"
              },
              {
                "t": "11:43",
                "label": "舉手沒被叫到...",
                "action": "暫停"
              },
              {
                "t": "12:26",
                "label": "怎麼幫幫壞心情呢 ?",
                "action": "暫停"
              },
              {
                "t": "13:53",
                "label": "沒有人可以聊天...",
                "action": "暫停"
              },
              {
                "t": "14:23",
                "label": "怎麼幫幫壞心情呢 ?",
                "action": "暫停"
              },
              {
                "t": "15:33",
                "label": "一個人也沒有的地方，很害怕...",
                "action": "暫停"
              },
              {
                "t": "16:01",
                "label": "自己解決，還是找人幫忙好呢 ?",
                "action": "暫停"
              },
              {
                "t": "17:05",
                "label": "碰到不會的問題，很緊張...",
                "action": "暫停"
              },
              {
                "t": "17:31",
                "label": "自己解決，還是找人幫忙好呢 ?",
                "action": "暫停"
              },
              {
                "t": "21:45",
                "label": "發下壞心情賓果卡",
                "action": "暫停"
              },
              {
                "t": "30:04",
                "label": "帶領討論：想一想、說說看",
                "action": "暫停"
              },
              {
                "t": "30:55",
                "label": "第一題",
                "action": "暫停"
              },
              {
                "t": "31:14",
                "label": "第二題",
                "action": "暫停"
              },
              {
                "t": "31:33",
                "label": "第三題",
                "action": "暫停"
              },
              {
                "t": "31:51",
                "label": "第四題",
                "action": "暫停"
              },
              {
                "t": "32:10",
                "label": "第五題",
                "action": "暫停"
              }
            ]
          }
        ]
      },
      {
        "id": 5,
        "title": "第5課-老師超魔力",
        "videos": [
          { "id": "4b24829c269842258ee70a1e60a9d0c3", "title": "影片2" }
        ]
      },
      {
        "id": 6,
        "title": "第6課-魔力好時機",
        "videos": [
          { "id": "d348381dad5a40f8b522dc216ce2d879", "title": "影片9" }
        ]
      },
    ]
  };


  if (courseData == null) return <p>課程資料載入中</p>;
  else {
    var course_id = course.grade + '-' + course.semester;
    courseData["chapters"] = course.chapters;
  }

  return (
    <Container>
      <Accordion defaultActiveKey={course_id}>
        <Card key={course_id}>
          <Card.Header as="h2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{course.title}</span>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Link to="/files/扶輪芯福影音EQ課-教師操作手冊0924.pdf" target="_blank" download style={{ fontSize: '12pt' }}>
                教師手冊下載<GetAppIcon style={{ marginLeft: '5px' }} />
              </Link>
              <a href="https://training.happinessvillage.org/24" target="_blank" style={{ fontSize: '12pt' }}>
                理論課 <SchoolIcon style={{ marginLeft: '5px' }} />
              </a>
            </div>
          </Card.Header>
          <Card.Body>
            {course.chapters.map((chapter, idx_chapter) =>
              <div key={idx_chapter} id={`SEC-${course.id}-${idx_chapter}`}>
                <h4 className="h4" style={{ paddingTop: .4 + 'em', fontSize: '12pt' }}>{chapter.title}</h4>
                <ListGroup>
                  {chapter.videos.map((v, videoIndex) =>
                    <ListGroupItem key={+ '_' + idx_chapter + '_' + videoIndex} style={{ fontSize: '12pt' }}>
                      <ChapterVideo idx={`Y${course.grade}-S${course.semester}`} video={v} />
                    </ListGroupItem>
                  )}
                </ListGroup>
              </div>
            )}
          </Card.Body>
        </Card>
      </Accordion>
    </Container>
  );
}


export { Course };