import Repo from './Base';
import authService from '../components/api-authorization/AuthorizeService';

const resource = '/me';

export default {

  async getMe() {
    const token = await authService.getAccessToken();
    let result = await Repo.get(
      `${resource}`,
      { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
    if (result.status === 200) {
      return result.data;
    } else {
      throw result;
    }
  },

  async getMyCourses() {

    var resultx = [
      {
        "grade": 1,
        "semester": 1,
        "title": "★扶輪芯福線上EQ課(一上)"
      }/*,
      {
        "grade": 1,
        "semester": 2,
        "title": "★扶輪芯福線上EQ課(一下)"
      },
      {
        "grade": 2,
        "semester": 1,
        "title": "★扶輪芯福線上EQ課(二上)"
      },
      {
        "grade": 2,
        "semester": 2,
        "title": "★扶輪芯福線上EQ課(二下)"
      }
    */];

    const token = await authService.getAccessToken();
    try {
      let result = await Repo.get(
        `${resource}/classes`,   // 原本課程系統是 classes, 這裡改成 courses
        { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
      if (result.status === 200) {
        console.log(result.data);
        return resultx;
      }
    } catch (error) {
      if (error.response && (error.response.status === 404 || error.response.status === 500)) {
        console.error(`Error: ${error.response.status} - ${error.response.statusText}`);
      } else {
        console.error('An unexpected error occurred:', error);
      }
      throw error;
    }
  }
}