import React, { StrictMode, useState, useEffect, createContext } from 'react';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom'
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Detector } from './components/Detector';
import EQClass from './components/EQClass';
import authService from './components/api-authorization/AuthorizeService';

import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import RepoMe from './repository/RepoMe';

import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './custom.css'

export const UserContext = createContext(null);

export default function App() {

  const location = useLocation();
  const [myCourses, setMyCourses] = useState([]);
  const [user, setUser] = useState(null);
  const [loadError, setLoadError] = useState(null);

  async function populateState() {
    const [isAuth] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
    let me = {};
    let myCourses = [];
    try {
      me = await RepoMe.getMe();
      console.log(me);
      myCourses = await RepoMe.getMyCourses();
      console.log(myCourses);
    } catch (err) {
      setLoadError(err);
      if (isAuth) {
        if (err.status === 401) {
          if (!location.pathname.startsWith(ApplicationPaths.ApiAuthorizationPrefix)) {
            window.location = "/authentication/login";
          }
        }
        console.log("is Auth ", ApplicationPaths.ApiAuthorizationPrefix);
        console.log(err);
      } else {
        console.log(err);
      }
    }
    setUser(me);
    setMyCourses(myCourses);
    if (!isAuth)
      console.log("--- not authenticated.");
  };

  useEffect(() => {
    var x = authService.subscribe(() => {
      populateState();
    });
    populateState();
    return () => { authService.unsubscribe(x); }
  }, []);

  return (
    <StrictMode>
      <UserContext.Provider value={{ myCourses: myCourses, user, loadError }}>
        <BrowserRouter>
          <Layout>
            <Switch>
              <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
              <Route exact path='/t' component={Detector} />
              <AuthorizeRoute exact path='/' component={Home} />
              <AuthorizeRoute exact path='/Course/Y:grade-S:semester' component={EQClass} />
            </Switch>
          </Layout>
        </BrowserRouter>
      </UserContext.Provider>
    </StrictMode>
  );
}
